<script>
import Vue from "vue";
import store from "@/state/store";

import { required, email } from "vuelidate/lib/validators";

import { mapState } from "vuex";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/auth";
import {
  AccountStatus,
  notificationMethods, SessionLoginState
} from "@/state/helpers";



/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Account setup",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      email: "",
      submitted: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    email: { required, email }
  },
  sockets: {
    email_verified() {
      const router = this.$router; // Promise hell...
      this.$swal({
        icon: 'success',
        title: '',
        text: this.$t('setup.email.verify.success'),
        confirmButtonText: this.$t('generic.continue')
      }).then(function() {
        router.push({name: "setup_account"}).catch((error) => {});
      });
    }
  },
  methods: {
    ...notificationMethods,
    handle_bad_address() {
      this.submitted = false;
      this.$swal( {
        icon: 'error',
        title: '',
        text: this.$t('setup.email.errors.invalid_statement')
      });
    },
    handle_unavailable_address(provider) {
      this.submitted = false;
      this.$swal( {
        icon: 'warning',
        title: this.$t('setup.email.errors.unavailable'),
        text: this.$t('setup.email.errors.unavailable_statement', {provider: provider})
      });
    },
    handle_taken() {
      this.submitted = false;
      this.$swal( {
        icon: 'warning',
        title: '',
        text: this.$t('setup.email.errors.taken')
      });
    },
    handle_fail_and_reset() {
      this.submitted = false;
      this.$swal( {
        icon: 'error',
        title: this.$t('error.server.generic.title'),
        text: this.$t('error.server.generic.message')
      });
    },
    handle_rate_limit_error() {
      this.submitted = false;
      this.$swal( {
        icon: 'warning',
        title: this.$t('error.server.ratelimit.title'),
        text: this.$t('error.server.ratelimit.custom.email')
      });
    },
    handle_success(state) {
      const router = this.$router; // Promise hell...
      this.$swal({
        icon: 'info',
        title: '',
        text: this.$t('setup.email.verify.info'),
        confirmButtonText: this.$t('generic.continue')
      }).then(function() {
        router.push({name: "setup_email_verify"}).catch((error) => {});
      });
    },
    try_submit() {
      if(this.submitted) return;
      this.submitted = true;

      this.$v.$touch();
      if(this.$v.$invalid) {
        this.submitted = false;
      } else {
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          }
        })
        .then(data => {
          /* Actual request */
          let payload = {
            acsrf_token: data.acsrf_token,
            email: this.email
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/update-email', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
              .then(response => {
                return response.json();
              })
              .then(data => {
                if(!data.status) {
                  if(data.error === 'invalid-state') {
                    /* Local state got desynchronized, reevaluating */
                    console.log('Resolving state desynchronization');
                    this.$router.push({name: "login"}).catch((error) => {});
                  } else if(data.error === 'rate-limited') this.handle_rate_limit_error();
                    else if(data.error === 'bad-address') this.handle_bad_address();
                    else if(data.error === 'unavailable-address') this.handle_unavailable_address(data.provider);
                    else if(data.error === 'taken') this.handle_taken();
                    else this.handle_fail_and_reset();
                }
                else this.handle_success(data.followup);
              })
              .catch(error => {
                console.log(`[ERROR] ${error}`);
                this.handle_fail_and_reset();
              });
          /* --- */
        })
        .catch(error => {
          console.log(error);
          this.handle_fail_and_reset();
        });
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-12">
                <div class="text-primary p-3">
                  <h4 class="text-white text-right p-0">{{$t('setup.email.title')}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
            >{{notification.message}}</div>
            <div class="p-2">
              <form @submit.prevent="try_submit">
                <div class="form-group">
                  <label for="email">{{$t('generic.email')}}</label>
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    id="email"
                    :class="{ 'is-invalid': $v.email.$error }"
                    @change="$v.email.$touch()"
                  />
                  <div v-if="$v.email.$error" class="invalid-feedback">
                    <span v-if="!$v.email.required">{{$t('setup.email.errors.required')}}</span>
                    <span v-if="!$v.email.email">{{$t('setup.email.errors.invalid')}}</span>
                  </div>
                </div>
                <div class="mt-3">
                  <b-button type="submit" variant="primary" class="btn-block">{{$t('generic.continue')}}</b-button>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="mt-3 text-center">
      <small>
        © 2017 - {{new Date().getFullYear()}} {{$t('generic.legal_disclaimer')}} {{$t('generic.provider')}}<br>
        <small style="font-size: 10px;">
          <a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
          /
          <a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
        </small>
      </small>
    </div>
  </Layout>
</template>

<style lang="scss" module></style>
